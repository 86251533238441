import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as COURSE from 'pgdb-data-layer/lib/Constants/Course';
import * as CPD_HELPERS from 'pgdb-data-layer/lib/Helpers/CPD';
import * as DASHBOARD_CARDS_HELPERS from 'pgdb-data-layer/lib/Helpers/DashboardCards';
import ResultActions, {
   isFetchingResult,
} from 'pgdb-data-layer/lib/Redux/ResultRedux';
import UserActions, {
   isFetchingUserInfo,
} from 'pgdb-data-layer/lib/Redux/UserInfoRedux';
import { getToken } from '../../../Session/SessionRedux';
import DashboardCard from '../index';

class CPDCard extends Component {
   constructor(props) {
      super(props);
      this.state = {
         currentYearPassed: false,
         isUserLicenced: false,
      };
   }

   componentDidMount() {
      const { dispatch, token } = this.props;
      dispatch(UserActions.getUserContactInfoRequest(token));
   }

   componentDidUpdate(prevProps) {
      const { userResult, userContactData } = this.props;
      if (userResult !== prevProps.userResult) {
         this.calculateUserCPD();
      }
      if (userContactData !== prevProps.userContactData) {
         this.calculateUserRegistration();
      }
   }

   calculateUserRegistration = () => {
      const { userContactData, dispatch, token } = this.props;
      if (CPD_HELPERS.checkUserRegistration(userContactData)) {
         // only proceed with getting user results if they have a registration/licence
         this.setState({ isUserLicenced: true });
         dispatch(
            ResultActions.getResultsByUserIdRequest(
               token,
               COURSE.TYPE_MANDATORY_CPD
            )
         );
      }
   };

   calculateUserCPD = () => {
      const { userResult } = this.props;
      const currentYearPassed = DASHBOARD_CARDS_HELPERS.calculateUserCPD(
         userResult
      );
      this.setState({ currentYearPassed });
   };

   render() {
      const { currentYearPassed, isUserLicenced } = this.state;
      const navLink = {
         href: 'cpd',
         text: currentYearPassed ? 'View' : 'Book',
      };
      const { isLoading } = this.props;
      return (
         <DashboardCard
            header="CPD"
            icon={currentYearPassed ? 'check' : 'clear'}
            iconStyle={currentYearPassed ? 'light-green-text' : 'red-text'}
            description={
               currentYearPassed
                  ? `You've met your CPD requirements`
                  : `You've not met your CPD requirements`
            }
            visible={!isLoading}
            disabled={!isLoading && !isUserLicenced} // only show tile if user is licenced
            navLink={navLink}
         />
      );
   }
}

CPDCard.propTypes = {
   userResult: PropTypes.any,
   userContactData: PropTypes.object,
   dispatch: PropTypes.func.isRequired,
   isLoading: PropTypes.bool.isRequired,
   token: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
   const userContactInfoBusy = isFetchingUserInfo('getUserContactInfo', state);
   const getResultsByUserIdBusy = isFetchingResult('getResultsByUserId', state);
   return {
      userContactData: state.user.contactData,
      userResult: state.result.data,
      token: getToken(state),
      isLoading: userContactInfoBusy || getResultsByUserIdBusy,
   };
};

export default connect(mapStateToProps)(CPDCard);
